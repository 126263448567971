import React, { useState } from 'react'
import './Home.css'
import {
  banner, logo3, logo4, logo5, logo6, logo7, logo8, girl, doctor, brane, door, injaction, fda, bgimg, locationbook, locationicon, bannerbran, tick,
  instagram,
  tiwtter, facebook, arc2,
  anxiety,
  bipolar,
  adhd,
  whitetick,
  amit,
  boris,
  getbanner
} from '../../assets/img';

const Home = ({ onConsultationClick }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  }
  const currentYear = new Date().getFullYear();


  return (
    <>
      <div>
        <div className='container py-md-4 py-2 border-top  border-bottom  border-3   border-secondery'>
          {/* <div className='row  d-flex justify-content-end ps-md-5 ps-0  ms-md-5 ms-0'>
            <div className='col-md-6'></div>
            <div className='col-md-6 row ms-5 px-4'>
              <div className='col-md-4 col-12 mb-2 ps-0'>
                <p className='mb-0 add_para_font '>Call us</p>
                <p className='text-color fw-bold font_size pb-0 mb-0 '>330-333-9016</p>
              </div>
              <div className='col-md-8 col-12 mb-2 ps-0'>
                <p className='mb-0'>Find Us</p>
                <p className='text-color fw-bold font_size pb-0 mb-0'>Beachwood | Canton | Medina | Youngstown</p>
              </div>
            </div>
          </div> */}
          <div className='row mx-auto'>
            <div className='col-md-7 add_transform col-12 mt-md-4 mt-2'>
              <h2 className='fs-size customFont fw-bold'>Where your mental <span className='health px-4'>Health</span> flourishes</h2>
              <div className='col-12 col-md-9 mt-4 para_two_div'>
                <p>Transcranial Magnetic Stimulation (TMS) offers numerous advantages over prescribed medications for treating mental health issues. </p>
                <p>TMS is a safe, effective non-drug treatment for depression. It works by sending magnetic pulses to stimulate neurons in the brain's
                  mood- regulating area. TMS is FDA-cleared and non-invasive. </p>
              </div>
              <div className='row ps-2  d-none d-md-block'>
                <div className='col-4 text-end p-0 d-flex align-items-center justify-content-center'>
                  <h1 className='fs-size eight '>8</h1>

                  <div className='col-md-10 text-start mt-md-2 mt-0 ps-1'>
                    <h1 className=' plus '><i class="fa fa-plus fw-bolder" aria-hidden="true"></i>
                    </h1>
                    <p className='text_size_set'>Years of Experience
                      Performing TMS </p>
                  </div>
                </div>

              </div>

              <div className='col-12 d-flex align-items-center justify-content-center justify-content-md-start d-none d-md-block'>
                <button type="button" class="btn btn rounded-pill px-mb-2 px-1 py-md-2 py-2 mt-2 btn_width customclor fw-bold fs-4" onClick={onConsultationClick}>Start TMS</button>
              </div>
            </div>
            <div className='col-md-5  px-md-auto col-12 '>
              <div className='row '>
                <div className='col-md-12 col-4 pt-4 px-3'>
                  <div className=' row px-2 '>
                    <div className='col-md-4 col-12 mb-2 ps-0' >
                      <p className='mb-0 add_para_font font_size '>Call us</p>
                      <a className=' fw-bold font_size pb-0 mb-0 ' href='tel:330-333-9016'  > <span className='text-color font_size '>330-333-9016</span></a>
                    </div>
                    <div className='col-md-8 col-12 mb-2 ps-0'>
                      <p className='mb-0 font_size'>Find Us</p>
                      <p className='text-color fw-bold font_size pb-0 mb-0'>Beachwood | Canton | Medina | Youngstown</p>
                    </div>
                  </div>

                  <div className='row ps-2  d-block d-md-none'>
                    <div className='col-12 text-end p-0 d-flex align-items-center justify-content-center'>
                      <h1 className=' eight row_eight mb-0'>8</h1>

                      <div className='col-11 text-start mt-md-2 mt-0 ps-1'>
                        <h1 className=' plus mb-0 '><i class="fa fa-plus fw-bolder" aria-hidden="true"></i>
                        </h1>
                        <p className='text_size_set mb-3'>Years of Experience
                          Performing TMS </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-md-12 col-8'>
                  <img src={banner} alt="" className='banner  img-fuild  d-none d-md-block' />
                  <img src={getbanner} alt="" className='banner mt-1 img-fuild  d-block d-md-none' />
                  {/* <img src={banner} alt="" className=' mt-4 img-fuild d-block d-md-none ' /> */}
                </div>
              </div>
            </div>
            <div className='col-12 mb-2  d-flex align-items-center justify-content-center justify-content-md-start d-block d-md-none'>
              <button type="button" class="btn btn rounded-pill px-mb-2 px-1 py-md-2 py-2 mt-2 btn_width22 customclor fw-bold fs-4" onClick={onConsultationClick}>Start TMS</button>
            </div>
          </div>
        </div>
        <div className='container text-center pb-2  border-3 pt-md-4 pt-2 border-secondery'>
          <h2 className='customFont add_head_data fw-bold mb-0 mb-md-1 '><b>Covered by most insurance plans </b></h2>
          <i className='mt-2 add_itlic_data'>We handle the paper work so you can focus on treatment </i>
          <section className='wrapper mt-3'>
            <div className='slider'>
              <div className='d-flex'>
                <div className='  '>
                  <img src={logo3} className='w-75'></img>
                </div>
                <div className='  '>
                  <img src={logo4} className='w-75 '></img>
                </div>
                <div className='  '>
                  <img src={logo5} className='w-75'></img>
                </div>
                <div className=' '>
                  <img src={logo6} className='w-75'></img>
                </div>
                <div className='  '>
                  <img src={logo7} className='w-75'></img>
                </div>
                <div className='  '>
                  <img src={logo8} className='w-75'></img>
                </div>

              </div>
            </div>
          </section>
          <p className='m-0 p-0 d-flex align-items-center justify-content-center  add_itlic_data'>
            and many more!
          </p>
        </div>
        <div className='container'>
          <p className='p-0 m-0 d-flex align-items-center justify-content-end text-black-50  tc pb-1'><i className='ms-2'>
            T&C Apply
          </i></p>

        </div>
        <div className='container py-md-3 py-2  px-md-0 px-4 border-bottom border-top border-3  border-secondery'>
          <h1 className='text-center customFont  add_head_data fs-bold'><b>What are you struggling with? </b></h1>
          <div className='row mt-md-5 mt-4 mx-auto mb-md-4 mb-2'>
            <div className='col-md col-12 mb-3 mb-md-1 bg-color px-2 rounded-4 py-2 mx-md-2 mx-auto pb-3 d-none d-md-block'>
              <div>
                <div className='row mx-auto align-items-center add_small_custom_height'>
                  <div className='col-md-4 col-3  text-start ps-4 pe-0'>
                    <img src={girl} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>Depression </h3>
                  </div>
                </div>
                <p className='px-md-3 px-4 pt-md-2 pt-0 pb-0 text-white add_custom_height'>Do you feel like your sadness just
                  won't go away, and it is hard to find a way ahead? We can help.</p>
              </div>

            </div>
            <div className='col-md col-12 mb-3 mb-md-1 bg-color px-2 rounded-4 py-3 mx-md-2 mx-auto pb-3 d-block d-md-none'>
              <div>
                <div className='row mx-auto align-items-center add_small_custom_height'>
                  <div className=' col-4  text-start ps-3'>
                    <img src={girl} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>Depression </h3>
                    <p className=' pe-4 pt-0 pb-0 text-white add_custom_height'>Do you feel like your sadness just
                      won't go away, and it is hard to find a way ahead? We can help.</p>
                  </div>
                </div>

              </div>

            </div>
            <div className='col-md col-12 mb-3 mb-md-1 bg-color px-2 rounded-4 py-2 mx-md-2 mx-auto pb-3 d-none d-md-block'>
              <div>
                <div className='row mx-auto align-items-center add_small_custom_height'>
                  <div className='col-md-4  col-3 text-start ps-4 pe-0'>
                    <img src={anxiety} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>Anxiety Disorder </h3>
                  </div>
                </div>
                <p className='px-md-3 px-4 pt-md-2 pt-0 pb-0 text-white add_custom_height'>Do you often feel restless, worried or on-edge? Let us help you on how to cope better.</p>
              </div>

            </div>

            <div className='col-md col-12 mb-3 mb-md-1 bg-color px-2 rounded-4 py-3 mx-md-2 mx-auto pb-3 d-block d-md-none'>
              <div>
                <div className='row mx-auto align-items-center add_small_custom_height'>
                  <div className=' col-4 text-start ps-3'>
                    <img src={anxiety} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>Anxiety Disorder </h3>
                    <p className=' pe-4  pt-0 pb-0 text-white add_custom_height'>Do you often feel restless, worried or on-edge? Let us help you on how to cope better.</p>
                  </div>
                </div>

              </div>

            </div>
            <div className='col-md col-12 mb-3 mb-md-1 bg-color px-2 rounded-4 py-2 mx-md-2 mx-auto pb-3 d-none d-md-block'>
              <div>
                <div className='row mx-auto align-items-center add_small_custom_height'>
                  <div className='col-md-4  col-3  text-start ps-4 pe-0'>
                    <img src={bipolar} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>Bipolar Disorder </h3>
                  </div>
                </div>
                <p className='px-md-3 px-4 pt-md-2 pt-0 pb-0 text-white add_custom_height'>Do you struggle with periods of intense happiness, followed by intense sadness? You can find the care you need with us.</p>
              </div>

            </div>
            <div className='col-md col-12 mb-3 mb-md-1 bg-color px-2 rounded-4 py-3 mx-md-2 mx-auto pb-3 d-block d-md-none'>
              <div>
                <div className='row mx-auto align-items-center add_small_custom_height'>
                  <div className=' col-4  text-start ps-3'>
                    <img src={bipolar} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>Bipolar Disorder </h3>
                    <p className=' pe-4  pt-0 pb-0 text-white add_custom_height'>Do you struggle with periods of intense happiness, followed by intense sadness? You can find the care you need with us.</p>

                  </div>
                </div>
              </div>

            </div>
            <div className='col-md col-12 mb-3 mb-md-1  bg-color px-2 rounded-4 py-2 mx-md-2  mx-auto pb-3 d-none d-md-block'>
              <div>
                <div className='row mx-auto align-items-center add_small_custom_height'>
                  <div className='col-md-4  col-3  text-start ps-4 pe-0'>
                    <img src={adhd} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>ADHD's</h3>
                  </div>
                </div>
                <p className='px-md-3 px-4 pt-md-2 pt-0 pb-0 text-white add_custom_height'>Have you always struggled with difficulty focussing, being restless, or impulsivity? There are ways to manage it better.</p>
              </div>
            </div>
            <div className='col-md col-12 mb-3 mb-md-1  bg-color px-2 rounded-4 py-3 mx-md-2  mx-auto pb-3 d-block d-md-none'>
              <div>
                <div className='row mx-auto  align-items-center add_small_custom_height'>
                  <div className='col-4  text-start ps-3'>
                    <img src={adhd} className='img-icom'></img>
                  </div>
                  <div className='col-8 ps-0'>
                    <h3 className='text-white fw-bold fs-5'>ADHD's</h3>
                    <p className=' pe-4 pt-md-2 pt-0 pb-0 text-white add_custom_height'>Have you always struggled with difficulty focussing, being restless, or impulsivity? There are ways to manage it better.</p>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='container  border-bottom  border-3  border-secondery'>
          <div className='row py-md-4    py-0  mx-md-3 mx-0 pb-0'>
            <div className='col-md-6 col-12 px-md-2 px-0'>
              <div>
                <img className='w-100 shadow mt-md-5 mt-3 customradius' alt="" src={doctor} />
              </div>
            </div>
            <div className='col-md-6 col-12 px-md-4 px-0 ps-md-5 ps-0'>
              <div className='px-md-4 px-0 mt-4 mt-md-0'>
                <h3 className='m-0 p-0 add_font_second text-center text-md-start'>WHAT IS TMS THERAPY? </h3>
                <h2 className='add_head_data customFont fw-bold'><b>A different way to treat
                  depression
                </b></h2>
                <div href="#section1" onClick={() => scrollToSection('section1')} className='row mx-1 p-md-3 p-2 px-0 shadow mt-4 mt-md-5 section_box' >
                  <div className='col-md-2 col-3 d-flex align-items-center justify-content-center '>
                    <img className='add_img_weight ms-4 ms-md-0 ' src={brane} />
                  </div>
                  <div className='col-md-10 col-9 text-white'>
                    <h4 className='mb-0 pb-0 pb-md-1 mb-md-1 add_font_heading_data works'><b>HOW IT WORKS</b><span className='ms-4'><i class="fa fa-angle-right fs-3" aria-hidden="true"></i>
                    </span> </h4>
                    <p className='mb-0 pb-0 add_font_para_text'>Learn How TMS Therapy treats depression </p>
                  </div>
                </div>
                <div className='row mx-1 p-md-3 p-2 px-0 shadow mt-4 section_box' onClick={onConsultationClick}>
                  <div className='col-md-2 col-3 d-flex align-items-center justify-content-center '>
                    <img className='add_img_weight ms-4 ms-md-0' src={door} />
                  </div>
                  <div className='col-md-10 col-9 text-white'>
                    <h4 className='mb-0 pb-0 pb-md-1 mb-md-1 add_font_heading_data'><b>WHAT TO EXPECT </b><span className='ms-2'><i class="fa fa-angle-right fs-3" aria-hidden="true"></i>
                    </span> </h4>
                    <p className='mb-0 pb-0 add_font_para_text'>We'll walk you through treatment</p>
                  </div>
                </div>

              </div>
              <div>
                <div className='row py-md-3 py-2 px-md-4 px-3'>
                  <div className='col-6 d-flex ps-5 align-items-center'>

                    <img className='add_two_img' src={fda} />

                    <p className='mb-0 text-color' style={{ whiteSpace: "nowrap" }}><b>FDA Cleared</b></p>
                  </div>
                  <div className='col-6 d-flex align-items-center'>

                    <img className='add_two_img' src={injaction} />

                    <p className='mb-0 text-color' style={{ whiteSpace: "nowrap" }}><b>Durg Free</b></p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className=' container border-bottom  border-3  border-secondery py-md-5 pb-0 pt-md-5 pt-0 '>
          <div className='bg-img py-md-5 py-3'>
            {/* <img src={bgimg} alt='' /> */}
            <div className='text-center'>
              <img className='location' src={locationbook} />
              <h1 className='my-3 fw-bold customFont add_head_data'>Find your closest ARC Psychiatry TMS </h1>
              <p className='add_itlic_data'>We have convenient locations close to home, work, or <br />
                school, so that you’re able to fit TMS into your schedule</p>
            </div>
            <div className='row mt-md-5 mt-0 d-flex align-items-center justify-content-center jstify-content-md-start ps-md-5 '>
              <div className='col-md col-6  border_right' >
                <a href={'https://maps.app.goo.gl/YTCn7tJdfX7zuQ3F6'} target='_blank'>

                  <div className='row'>
                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                      <img src={locationicon} className='img-icom2 '></img>
                    </div>
                    <div className='col-8 ps-0'>
                      <h3 className='text-color fs-5'>Beachwood  </h3>
                      <p className='add_font_small'> <b>25700 Science Park, Suite 210,
                        Beachwood, OH 44122.</b> </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-md col-6 border_right' >
                {/* <a href={'https://maps.app.goo.gl/ePMestN2wSweSwhu7'} target='_blank'>

                  <div className='row setheight'>
                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                      <img src={locationicon} className='img-icom2 '></img>
                    </div>
                    <div className='col-8 ps-0'>
                      <h3 className='text-color fs-5'></h3>
                      <p className='add_font_small '><b> 4510 Dressler Rd NW Canton, OH 44718</b> </p>
                    </div>
                  </div>
                </a> */}
                <a href={'https://maps.app.goo.gl/ePMestN2wSweSwhu7'} target='_blank' className='d-block d-md-none'>

                  <div className='row'>
                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                      <img src={locationicon} className='img-icom2 '></img>
                    </div>
                    <div className='col-8 ps-0'>
                      <h3 className='text-color fs-5'>Canton </h3>
                      <p className='add_font_small'> <b>4510 <br /> Dressler <br /> Rd NW Canton,<br /> OH 44718</b> </p>
                    </div>
                  </div>
                </a>
                <a href={'https://maps.app.goo.gl/ePMestN2wSweSwhu7'} target='_blank' className='d-none d-md-block'>

                  <div className='row'>
                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                      <img src={locationicon} className='img-icom2 '></img>
                    </div>
                    <div className='col-8 ps-0'>
                      <h3 className='text-color fs-5'>Canton </h3>
                      <p className='add_font_small'> <b>4510 Dressler Rd NW Canton, OH 44718</b> </p>
                    </div>
                  </div>
                </a>

              </div>
              <div className='col-md col-6 border_right'>
                <a href={'https://maps.app.goo.gl/RegzWDKwBGdcyc6x5'} target='_blank'>
                  <div className='row'>
                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                      <img src={locationicon} className='img-icom2 '></img>
                    </div>
                    <div className='col-8 ps-0'>
                      <h3 className='text-color fs-5'>Medina  </h3>
                      <p className='add_font_small '><b>3591 Reserve Commons, Suite 100, Medina, OH 44256</b> </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-md col-6'>
                <a href={'https://maps.app.goo.gl/mWsTkmKh7QmcE5Dj8'} target='_blank'>

                  <div className='row'>
                    <div className='col-md-2 col-4 d-flex align-items-start justify-content-center pe-0 text-start'>
                      <img src={locationicon} className='img-icom2 '></img>
                    </div>
                    <div className='col-8 ps-0'>
                      <h3 className='text-color fs-5'>Westlake </h3>
                      <p className='add_font_small'><b>29055 Clemens Rd., Suite A Westlake, OH 44145</b> </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='container border-bottom  border-3  border-secondery py-4 px-md-0 px-3' id="section1">
          <div className='text-center'>
            <h1 className='py-md-4 pb-0 pt-0 fw-bold customFont add_head_data '>Magnetic pulses to ease depression</h1>
            <p className='px-md-5 px-0 alignText ms-md-5 ms-0 mb-0 pb-0 add_itlic_data'><b>Transcranial Magnetic Stimulation (TMS)</b> is a non-invasive treatment that uses magnetic fields to stimulate nerve cells in the brain. It is primarily used to treat depression and has shown effectiveness in reducing symptoms where medications have failed. TMS involves placing a magnetic coil against the scalp, delivering magnetic pulses to specific brain regions. This stimulation helps regulate mood and alleviate depressive symptoms. The procedure is FDA-cleared, safe, and generally well-tolerated with minimal side effects. </p>
            <img src={bannerbran} className='w-75 mt-0 pt-0' />
          </div>
        </div>
        <div className=' bg-color mt-md-5 mt-2 '>
          <div className='container text-white border-3  border-secondery '>
            <div className='row py-md-5 py-4 mx-auto '>
              <div className='col-12 col-md-8  text-md-start text-center'>
                <h4 className='mb-0 mb-md-1'><b className='fs-3'>Ready to get started?</b></h4>
                <h4 className='fw-normal mb-0 mb-md-1  fs-5'>You can do this, we can help.
                </h4>
                <div className='row py-md-4 py-3 d-flex justify-content-end'>
                  <div className='col-md col-9 d-flex '>
                    <img className='customheight' src={whitetick} />
                    <p className='mb-0 pb-0 add_itlic_datas'>Covered by Insurance</p>
                  </div>
                  <div className='col-md col-9 mt-1 mt-md-0 d-flex'>
                    <img className='customheight' src={whitetick} />
                    <p className='mb-0 pb-0 add_itlic_datas'>Locations in your area </p>
                  </div>
                  <div className='col-md col-9  justify-content-md-start mt-1 mt-md-0 d-flex'>
                    <img className='customheight' src={whitetick} />
                    <p className='mb-0 pb-0 add_itlic_datas'>Free Consultations </p>
                  </div>

                </div>
              </div>
              <div className='col-12 col-md-4 text-center'>
                <a type="button" href='tel:330-333-9016' className="add_itlic_datas btn btn-outline-light rounded-pill mb-2 px-mb-5 px-2 py-md-3 py-2  btn_width" >
                  <b className='text-white cll' >Call:330-333-9016</b>
                </a>

                <button type="button" class="add_itlic_data size-set btn btn-light rounded-pill px-mb-5 px-2 py-md-3 py-2 mt-md-3 mt-1 btn_width customcolor  fw-bold" onClick={onConsultationClick}>Request Your Free Consultation</button>
                {/* <button type="button" className="btn btn rounded-pill px-mb-5 px-2 py-md-3 py-2 mt-2 btn_width size-set customcolor btn-light fw-bold" onClick={onConsultationClick}>Request Your Free Consultation</button> */}
              </div>
            </div>
            {/* <div className='row py-md-5 py-4 mx-auto d-md-none d-block '>
              <div className='col-md-8 col-12 text-md-start text-center'>
                <h4 className='mb-0 mb-md-1'><b className='fs-3'>Ready to get started?</b></h4>
                <h4 className='fw-normal mb-0 mb-md-1  fs-5'>You can do this, we can help.
                </h4>

              </div>
              <div className='row '>
                <div className='row col-7 py-md-4 py-3 d-flex justify-content-center'>
                  <div className=' d-flex '>
                    <img className='customheight' src={whitetick} />
                    <p className='mb-0 pb-0 add_itlic_datas'>Covered by Insurance</p>
                  </div>
                  <div className=' mt-1 mt-md-0 d-flex'>
                    <img className='customheight' src={whitetick} />
                    <p className='mb-0 pb-0 add_itlic_datas'>Locations in your area </p>
                  </div>
                  <div className=' justify-content-md-start mt-1 mt-md-0 d-flex'>
                    <img className='customheight' src={whitetick} />
                    <p className='mb-0 pb-0 add_itlic_datas'>Free Consultations </p>
                  </div>

                </div>
                <div className='col-4 py-auto text-center my-auto'>
                  <a type="button" href='tel:330-333-9016' className="add_itlic_datas btn btn-outline-light rounded-pill px-mb-5 px-2 py-md-3 py-2  btn_width" >
                    <b className='text-white' >Call:330-333-9016</b>
                  </a>

                  <button type="button" class="add_itlic_data size-set btn btn-light rounded-pill px-mb-5 px-2 py-md-3 py-2 mt-md-3 mt-1 btn_width customcolor  fw-bold" onClick={onConsultationClick}>Request Your Free Consultation</button>
                </div>
              </div>

            </div> */}
          </div>
        </div>
        <div className=''>
          <div className='container text-center px-md-5 px-0  my-md-5 my-2 border-bottom border-top  border-3  border-secondery '>
            <h1 className='customFont pt-4 mb-0 add_head_data'><b>Why Choose TMS? </b></h1>
            <div className='container mx-auto ps-md-5 px-4'>
              <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-md-10 col-12 '>
                  <div className='row my-md-5 my-4  justify-content-center'>
                    <div className='col-md-6 col-6 d-flex align-items-center justify-content-start'>
                      <img className=' tick' src={tick} />
                      <h5 className='fw-bold add_itlic_datas '>FDA-cleared</h5>
                    </div>
                    <div className='col-md-6 col-6 d-flex'>
                      <img className=' tick' src={tick} />
                      <h5 className='fw-bold add_itlic_datas'>Non-invasive </h5>
                    </div>
                  </div>
                  <div className='row my-md-5 my-3  justify-content-center '>
                    <div className='col-md-6 col-6 d-flex align-items-center  justify-content-start text-start'>
                      <img className=' tick' src={tick} />
                      <h5 className='fw-bold  add_itlic_datas'>Non-drug treatment that
                        helps with depression
                      </h5>
                    </div>
                    <div className='col-md-6 col-6 d-flex align-items-center  justify-content-start text-start'>
                      <img className=' tick' src={tick} />
                      <h5 className='fw-bold add_itlic_datas'>Can resume normal everyday routine after session  </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container border-bottom  border-3  border-seconder  pb-md-4 pb-3 px-md-0'>
          <div className='text-center '>
            <h1 className='mb-4 customFont fs-1 add_head_data mt-4'><b>Meet Our Physicians </b></h1>
          </div>
          <div className='row ms-md-4 ms-md-0 mx-auto'>
            <div className='col-md-4 col-12'>
              <img src={amit} className='img_one img-fluid' />
            </div>
            <div className='col-md-7 moblie_center col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-none d-md-block'>
              <h4><b className='border-bottom border-3 border-dark fs-5'>Amit Mohan, MD </b><br /></h4>
              <h4 className='customcolor fs-6'> Executive Director</h4>
              <p className='para_text add_itlic_data mt-md-0 mt-3'><b className='customcolor'>Dr. Amit Mohan</b>, an Executive Director at <b className='customcolor'>ARC Psychiatry and TMS Specialist</b>, is Board Certified in Adult Psychiatry, Sleep Medicine, and Addiction Medicine. He completed his residency training and internship at Mayo Clinic College of Medicine and subsequently did his fellowship in Sleep Medicine at Cleveland Clinic.

                <br /><br />
                Dr. Mohan is a distinguished member of The American Medical Association, American Academy of Sleep Medicine, and the <b className='customcolor'>American Psychiatric Association</b>. He provides medication management for adults 18 years of age and older, leveraging his extensive experience in both inpatient and outpatient settings to treat a wide range of mental disorders.
                <br /><br />
                Dr. Mohan has a keen interest and specialization in Transcranial Magnetic Stimulation (TMS), having completed a Visiting Fellowship in TMS at Duke University. His expertise in this non-invasive procedure highlights his commitment to incorporating innovative treatments for mental health disorders, particularly for patients who may not respond to traditional therapies.
                <br /><br />
                Beyond his professional achievements, Dr. Mohan enjoys a variety of hobbies that contribute to his holistic approach to wellness. He is an enthusiastic traveler, exploring new cultures and cuisines around the world. In his free time, he loves cooking, experimenting with different recipes and ingredients. Additionally, he is an avid hiker, finding peace and inspiration in nature. Dr. Mohan also enjoys reading, with a particular interest in historical biographies, mindfulness, self-help, and contemporary fiction, which provides him with a well-rounded perspective on life and humanity.
              </p>

              {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
            </div>
            <div className='col-md-7 moblie_center col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-block d-md-none'>
              <h4><b className='border-bottom border-3 border-dark fs-5'>Amit Mohan, MD </b><br /></h4>
              <h4 className='customcolor fs-6'> Executive Director</h4>
              <p className='para_text add_itlic_data mt-md-0 mt-3'><b className='customcolor'>Dr. Amit Mohan</b>, an Executive Director at <b className='customcolor'>ARC Psychiatry and TMS Specialist</b>, is Board Certified in Adult Psychiatry, Sleep Medicine, and Addiction Medicine. He completed his residency training and internship at Mayo Clinic College of Medicine and subsequently did his fellowship in Sleep Medicine at Cleveland Clinic.
                <span style={{ display: isExpanded ? 'none' : 'inline' }}>...</span>
                <span style={{ display: isExpanded ? 'inline' : 'none' }}>
                  <br /><br />
                  Dr. Mohan is a distinguished member of The American Medical Association, American Academy of Sleep Medicine, and the <b className='customcolor'>American Psychiatric Association</b>. He provides medication management for adults 18 years of age and older, leveraging his extensive experience in both inpatient and outpatient settings to treat a wide range of mental disorders.
                  <br /><br />
                  Dr. Mohan has a keen interest and specialization in Transcranial Magnetic Stimulation (TMS), having completed a Visiting Fellowship in TMS at Duke University. His expertise in this non-invasive procedure highlights his commitment to incorporating innovative treatments for mental health disorders, particularly for patients who may not respond to traditional therapies.
                  <br /><br />
                  Beyond his professional achievements, Dr. Mohan enjoys a variety of hobbies that contribute to his holistic approach to wellness. He is an enthusiastic traveler, exploring new cultures and cuisines around the world. In his free time, he loves cooking, experimenting with different recipes and ingredients. Additionally, he is an avid hiker, finding peace and inspiration in nature. Dr. Mohan also enjoys reading, with a particular interest in historical biographies, mindfulness, self-help, and contemporary fiction, which provides him with a well-rounded perspective on life and humanity.
                </span>
                <button onClick={toggleReadMore} className=' bg-white  border-0' style={{ color: '#98C647 ' }}><b>
                  {isExpanded ? 'Read less' : 'Read more'}</b>
                </button></p>

              {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
            </div>
          </div>
          <div className='row mt-4 ms-md-4 ms-md-0 mx-auto'>
            <div className='col-md-4 col-12'>
              <img src={boris} className='img_two img-fluid' />
            </div>
            <div className='col-md-7   moblie_center  col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-none d-md-block'>
              <h4><b className='border-bottom border-3 border-dark fs-5'>Boris Royak, MD </b><br /></h4>
              <h4 className='customcolor fs-6'> Executive Director</h4>
              <p className='para_text add_itlic_data mb-0 mt-md-0 mt-3'>
                <b className='customcolor'> Dr. Boris Royak</b>, a summa cum laude graduate of Case Western Reserve University, is an Executive Director of ARC Psychiatry. He completed his residency training in psychiatry at Brown University.<br /><br />
                Dr. Royak is a Diplomate of the American Board of Psychiatry and Neurology and a member of the <b className='customcolor'>American Psychiatric Association</b>. With years of experience as a leader in healthcare systems, including roles as a Medical Director at hospitals and long-term care facilities, as well as a corporate consultant, he provides mental health services in a variety of settings. His areas of focus include Adult and Geriatric Psychiatry.<br /><br />
                Dr. Royak has a profound interest in Transcranial Magnetic Stimulation (TMS), a cutting-edge treatment for mental health disorders. He completed a Visiting Fellowship in TMS at Duke University, where he honed his skills and deepened his understanding of this innovative therapy. Dr. Royak is dedicated to advancing the use of TMS to treat patients with depression and other mental health conditions, particularly those who have not responded to traditional treatments. His expertise in TMS is a testament to his commitment to offering the latest and most effective treatment options to his patients.<br /><br />
                Outside of the office, Dr. Royak enjoys traveling and exploring new places, often capturing the beauty of his adventures through photography. His passion for discovering diverse cultures and landscapes enriches his approach to psychiatry, providing him with a broader perspective on the human experience
              </p>
              {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
            </div>
            <div className='col-md-7   moblie_center  col-12 p-0 ms-md-4 ms-0 px-md-0 px-3 d-block d-md-none'>
              <h4><b className='border-bottom border-3 border-dark fs-5'>Boris Royak, MD </b><br /></h4>
              <h4 className='customcolor fs-6'> Executive Director</h4>
              <p className='para_text add_itlic_data mb-0 mt-md-0 mt-3'>
                <b className='customcolor'> Dr. Boris Royak</b>, a summa cum laude graduate of Case Western Reserve University, is an Executive Director of ARC Psychiatry. He completed his residency training in psychiatry at Brown University.<span style={{ display: isExpanded ? 'none' : 'inline' }}>...</span>
                <span style={{ display: isExpanded ? 'inline' : 'none' }}>
                  Dr. Royak is a Diplomate of the American Board of Psychiatry and Neurology and a member of the <b className='customcolor'>American Psychiatric Association</b>. With years of experience as a leader in healthcare systems, including roles as a Medical Director at hospitals and long-term care facilities, as well as a corporate consultant, he provides mental health services in a variety of settings. His areas of focus include Adult and Geriatric Psychiatry.<br /><br />
                  Dr. Royak has a profound interest in Transcranial Magnetic Stimulation (TMS), a cutting-edge treatment for mental health disorders. He completed a Visiting Fellowship in TMS at Duke University, where he honed his skills and deepened his understanding of this innovative therapy. Dr. Royak is dedicated to advancing the use of TMS to treat patients with depression and other mental health conditions, particularly those who have not responded to traditional treatments. His expertise in TMS is a testament to his commitment to offering the latest and most effective treatment options to his patients.<br /><br />
                  Outside of the office, Dr. Royak enjoys traveling and exploring new places, often capturing the beauty of his adventures through photography. His passion for discovering diverse cultures and landscapes enriches his approach to psychiatry, providing him with a broader perspective on the human experience</span><button onClick={toggleReadMore} className=' bg-white  border-0 ' style={{ color: '#98C647 ' }}>
                  <b> {isExpanded ? 'Read less' : 'Read more'}</b>
                </button></p>

              {/* <div>
                <a href='#' className=''> <img src={facebook} className='facebook me-3 shadow p-2 rounded-5' /></a>
                <a href='#' className=''> <img src={instagram} className='instagram me-4 shadow p-2 rounded-5' /></a>
                <a href='#' className=''><img src={tiwtter} className='tiwtter shadow p-2 rounded-5' /> </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className='container  border-bottom  border-3  border-seconder pb-3 '>
          <div className='row'>
            <div className='col-md-6 p-md-5 pb-0 pt-4 align-items-center '>
              <h1 className='customFont fw-bold fs-1 add_head_data px-md-0 px-3'>Get Your FREE Consultation or Talk to Us Now </h1>
              <p className='para_text add_itlic_data mb-0 px-md-0 px-3 text-md-start text-center fw-blod'>Take this next step, we'll help with the others. </p>
            </div>
            <div className='col-md-4 col-12 text-center mx-auto my-auto mt-md-5 mt-3'>
              <a type="button" class="btn btn-secondary text-white rounded-pill px-mb-5 px-2 py-md-3 py-2 btn_width customclor1" href='tel:330-333-9016'> <b className='text-white'>Call: 330-333-9016</b>
              </a>
              <button type="button" class="btn btn rounded-pill px-mb-5 px-2 py-md-3 py-2 mt-2 btn_width customclor fw-bold" onClick={onConsultationClick}>Request Your Free Consultation</button>

            </div>
            {/* <div className='col-md-6 col-12 p-5'>
              <input placeholder='First Name ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <input placeholder='Last Name ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <input placeholder='Email ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <input placeholder='Phone Number ' className='rounded-2 py-1 px-2 mx-1 mt-4 border-success bordercolor' />
              <div className='text-center'>
                <button type="button" class="btn btn-color text-white px-4 fs-4 my-4 btnColor">Get More Info </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className='container mb-5 fs-7'>
          <div className='text-center my-md-5 my-4'>
            <img src={arc2} className='imgCLass' />
          </div>
          <div className='px-md-0 px-3'>
            <p className='text-justify add_itlic_data'>The medical information on this site is provided as an information resource only, and is not to be used or relied on for any diagnostic or treatment purposes. This information is not intended to be patient education, does not create any patient-physician relationship, and should not be used as a substitute for professional diagnosis and treatment. Always consult your health care provider before making any healthcare decisions or for guidance about a specific medical condition. </p>
            <p className='text-justify add_itlic_data'>TMS is indicated for the treatment of depressive episodes and for decreasing anxiety symptoms for those who may exhibit comorbid anxiety symptoms in adult patients suffering from Major Depressive Disorder (MDD) and who failed to achieve satisfactory improvement from previous antidepressant medication treatment in the current episode. TMS is intended to be used as an adjunct for the treatment of adult patients suffering from Obsessive-Compulsive Disorder (OCD) </p>
            <p className='text-justify add_itlic_data mb-2'><b className=''>Important Safety Information </b></p>
            <p className='text-justify add_itlic_data'>The most common side effect of TMS is pain or discomfort at or near the treatment site. These events are transient; they occur during the TMS treatment course and do not occur for most patients after the first week of treatment. There is a rare risk of seizure associated with the use of TMS therapy . </p>
          </div>
        </div>
     <div style={{background:'#454d53'}}>
      <div className=' container text-center py-5 '>
      <p className='px-2 copy'>
      © Copyright {currentYear} All Rights Reserved by ARC Psychiatry
    </p>        <div className='flex_rul justify-content-center copy  mx-5 '>
          <div className='mx-4 '> <a target='_blank' href='https://arcproviders.com/terms-and-conditions/' className='copy py-1 footer_text' >Terms and Conditions</a></div>
          <div className='mx-4'> <a target='_blank' href='https://arcproviders.com/privacy-policy-2/' className='copy py-1 footer_text'>Privacy Policy</a></div>
          <div className='mx-4'> <a target='_blank' href='https://arcproviders.com/notice-of-privacy-practices/' className='copy py-1 footer_text'>Notice of Privacy Practices</a></div>
        </div>
      </div>

     </div>
     
      </div >
      {/* <ConsultationForm show={showModal} handleClose={handleCloseModal} />    */}

    </>
  )
}

export default Home
